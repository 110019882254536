<template>
    <div class="relative">
        <svg ref="map" width="100%" height="100%"></svg>
        <sa-review style="position: absolute; bottom: 12rem; left: 15rem;" :star="reviews[0].stars" :text="reviews[0].text"
            :owner="reviews[0].owner" />
        <sa-review style="position: absolute; top: 15rem; left: 35rem;" :star="reviews[1].stars" :text="reviews[1].text"
            :owner="reviews[1].owner" />

        <sa-review style="position: absolute; bottom: 15rem; right: 10rem;" :star="reviews[3].stars"
            :text="reviews[3].text" :owner="reviews[3].owner" />
        <sa-counter style="position: absolute; bottom: 5%; right: 35%;" amount="25" title="Paises con Samii" description="Y haciendo crecer su negocio" />
        <sa-counter style="position: absolute; bottom: 5%; right: 0%;" amount="500,000" title="Ordenes creadas por nuestra comunidad" description="Usando nuestra plataforma" />

    </div>
</template>
<script>
import * as d3 from 'd3';
import { REVIEWS } from '../configuration';

export default {
    name: 'WorldMap',
    data() {
        return {
            countriesToHighlight: [
              'Mexico',
              'Argentina',
              'Bolivia',
              'Brazil',
              'Canada',
              'Chile',
              'Colombia',
              'Costa Rica',
              'Cuba',
              'Ecuador',
              'El Salvador',
              'Spain',
              'USA',
              'Guatemala',
              'Honduras',
              'Nicaragua',
              'Panama',
              'Paraguay',
              'Peru',
              'Dominican Republic',
              'Uruguay',
              'Venezuela',
              'Italy',
              'Greece'
            ],
            reviews: REVIEWS,
        };
    },
    mounted() {
        this.drawMap();
        window.addEventListener('resize', this.drawMap);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.drawMap);
    },
    methods: {
      drawMap() {
        const geoUrl = 'https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/world.geojson';

        const width = this.$refs.map.clientWidth;

        const height = Math.round(width / 2);

        const projection = d3.geoNaturalEarth1()
          .scale(width / 6)
          .translate([width / 2, height / 2]);

        const path = d3.geoPath().projection(projection);

        const svg = d3.select(this.$refs.map)
          .attr('width', width)
          .attr('height', height);

        svg.selectAll('*').remove();

        d3.json(geoUrl).then((data) => {
          const countries =data.features;
          svg.selectAll('path')
            .data(countries)
            .enter()
            .append('path')
            .attr('d', path)
            .attr("name", function(d) {return d.properties.name;})
            .attr('class', ({ id }) => id)
            .style('fill', (d) => {
              const countryName = d.properties.name;
              return this.countriesToHighlight.includes(countryName) ? 'rgb(139, 92, 246)' : '#ccc';
            })
            .style('stroke', '#fff')
            .style('stroke-width', 0.5)
        });
      },
    },
};
</script>

<style scoped>
.highlight {
    fill: #ff6347;
}

.default {
    fill: #dcdcdc;
}
</style>
