import FeatureOrders from "@/assets/img/feature-orders.png";
import FeatureInventory from "@/assets/img/feature-inventory.png";
import FeatureClients from "@/assets/img/feature-clients.png";
import FeatureFinances from "@/assets/img/feature-finances.png";

export const APPS = [
  { id: 0, text: "Formas de Pago", box: 2 },
  { id: 1, text: "Productos", box: 1 },
  { id: 2, text: "Status", box: 0 },
  { id: 7, text: "Fechas de Entrega", box: 0 },
  { id: 13, text: "Finanzas", box: 3 },
  { id: 3, text: "Categorias", box: -1 },
  { id: 4, text: "Contacto", box: 2 },
  { id: 5, text: "Gastos", box: 3 },
  { id: 6, text: "Ordenes", box: -1 },
  { id: 8, text: "Mensajeria", box: 2 },
  { id: 9, text: "Estadisticas", box: 3 },
  { id: 10, text: "Existencias", box: 1 },
  { id: 11, text: "Garantias", box: 0 },
  { id: 12, text: "Alertas", box: 1 },
];

export const FEATURES = [
  {
    name: "Ordenes",
    description:
      "Personaliza la forma en que llevas el control de tu negocio con una plataforma completamente moldeable a tus necesidades.",
    apps: [
      "Crea tus propios estatus",
      "Crea tu propio checklist de revision",
      "Controla tu negocio con imagenes, notas, etc",
      "Historial de movimientos de cada orden",
    ],
    image: FeatureOrders,
  },
  {
    name: "Inventario",
    description: "Administra tus inventarios de una manera sencilla",
    apps: [
      "Se actualiza al usar una pieza en alguna orden",
      "Inventario por sucursal",
      "Alertas de stock bajo",
      "Ordenes de compra",
    ],
    image: FeatureInventory,
  },
  {
    name: "Reportes",
    description:
      "Lleva la administracion completa de tu negocio con reportes de estadisticas, finanzas y gastos",
    apps: [
      "Reportes diarios, semanales y mensuales",
      "Control de gastos fijos (Renta, Luz, Sueldos, etc)",
      "Control de gastos variables (inventarios, comidas, etc)",
      "Reportes de estadisticas y finanzas",
    ],
    image: FeatureFinances,
  },
  {
    name: "Clientes",
    description: "Manten a tus clientes informados en todo momento",
    image: FeatureClients,
    apps: [
      "Historial de ordenes de tus clientes",
      "Notificaciones de ordenes/estatus por correo, whatsapp y notas",
      "Impresion tamano carta, termico, stickers",
    ],
  },
];

export const PRICING = [
  {
    id: 0,
    name: "Administracion de tareas",
    basic: true,
    pro: true,
    franchise: true,
  },
  {
    id: 1,
    name: "Administracion de clientes",
    basic: true,
    pro: true,
    franchise: true,
  },
  {
    id: 2,
    name: "Administracion de tu stock",
    basic: true,
    pro: true,
    franchise: true,
  },
  {
    id: 3,
    name: "Administracion de tus servicios",
    basic: true,
    pro: true,
    franchise: true,
  },
  {
    id: 4,
    name: "Administracion de proveedores",
    basic: true,
    pro: true,
    franchise: true,
  },
  {
    id: 5,
    name: "Soporte a usuarios",
    basic: true,
    pro: true,
    franchise: true,
  },
  {
    id: 6,
    name: "Aplicación movil",
    basic: true,
    pro: true,
    franchise: true,
  },
  {
    id: 7,
    name: "Finanzas basicas (ventas)",
    basic: true,
    pro: true,
    franchise: true,
  },
  {
    id: 8,
    name: "Finanzas completas",
    basic: false,
    pro: true,
    franchise: true,
  },
  {
    id: 9,
    name: "Alerta de stock bajo",
    basic: false,
    pro: true,
    franchise: true,
  },
  {
    id: 10,
    name: "Ordenes de compra",
    basic: false,
    pro: true,
    franchise: true,
  },
  {
    id: 11,
    name: "Control de gastos (fijos,variables)",
    basic: false,
    pro: true,
    franchise: true,
  },
  { id: 12, name: "Estadisticas", basic: false, pro: true, franchise: true },
  {
    id: 13,
    name: "Reportes diarios, semanales, mensuales",
    basic: false,
    pro: true,
    franchise: true,
  },
  { id: 14, name: "Multisucursal", basic: false, pro: true, franchise: true },
];

export const REVIEWS = [
  {
    id: 0,
    stars: 5,
    text: "SAMII transformo la manera en que gestiono mi taller. Ahora todo esta centralizado, y he reducido los erroes en las ordenes de trabajo. !Totalmente recomendado!",
    owner: "Carlos M.",
  },
  {
    id: 1,
    stars: 5,
    text: "Gracias a SAMII, mi taller ha ganado mucha eficiencia. Llevar el control de inventario es sencillo, y mis clientes notan la diferencia en la rapidez del servicio",
    owner: "Anahi G.",
  },
  {
    id: 2,
    stars: 5,
    text: "No sabia cuanto necesitaba SAMII hasta que lo probe. La interfaz es intuitiva y me ha permitido tener una vision completa de mi negocio en todo momento",
    owner: "Luis R",
  },
  {
    id: 3,
    stars: 5,
    text: "Desde que implemente SAMII en mi taller, la organizacion ha mejorado notablemente. Puedo seguir cada reparacion de principio a fin sin perder detalles importantes",
    owner: "Maria M",
  },
];

export default {
  APPS,
  FEATURES,
  PRICING,
  REVIEWS,
};
